export function getNowFormatDate(e) {
  let date = e,
    seperator1 = "-", //格式分隔符
    year = date.getFullYear(), //获取完整的年份(4位)
    month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
    strDate = date.getDate(); // 获取当前日(1-31)
  if (month >= 1 && month <= 9) month = "0" + month; // 如果月份是个位数，在前面补0
  if (strDate >= 0 && strDate <= 9) strDate = "0" + strDate; // 如果日是个位数，在前面补0

  let currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}

export function getNowFormatDate1(e) {
  let date = e,
    seperator1 = "-", //格式分隔符
    year = date.getFullYear(), //获取完整的年份(4位)
    month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
    strDate = 1; // 获取当前日(1-31)
  if (month >= 1 && month <= 9) month = "0" + month; // 如果月份是个位数，在前面补0
  if (strDate >= 0 && strDate <= 9) strDate = "0" + strDate; // 如果日是个位数，在前面补0

  let currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}

export function getNowFormatDate2(e) {
  let date = e,
    seperator1 = "-", //格式分隔符
    year = date.getFullYear(), //获取完整的年份(4位)
    month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
    // strDate = date.getDate(); // 获取当前日(1-31)
     maxDay = new Date(year, month, 0).getDate()
  // if (month == 12) {
  //   year = year + 1; //获取完整的年份(4位)
  //   month = 1;
  // } else {
  //   month = month + 1;
  // }
  // if (month >= 1 && month <= 9) month = "0" + month; // 如果月份是个位数，在前面补0
  // if (strDate >= 0 && strDate <= 9) strDate = "0" + strDate; // 如果日是个位数，在前面补0

  let currentdate = year + seperator1 + month + seperator1 + maxDay;
  return currentdate;
}

export function getNowFormatDate3(e) {
  let date = e,
    seperator1 = "-", //格式分隔符
    year = date.getFullYear(), //获取完整的年份(4位)
    month = 12, //获取当前月份(0-11,0代表1月)
    strDate = new Date(year, month, 0).getDate(); // 获取当前日(1-31)
  if (month >= 1 && month <= 9) month = "0" + month; // 如果月份是个位数，在前面补0
  if (strDate >= 0 && strDate <= 9) strDate = "0" + strDate; // 如果日是个位数，在前面补0

  let currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}

export function chagetime(e) {
  let time = e * 1000;
  time = new Date(time);
  let year = time.getFullYear(); //获取完整的年份(4位)
  let month = time.getMonth() + 1; //获取当前月份(0-11,0代表1月)
  let strDate = time.getDate(); // 获取当前日(1-31)
  let hour = time.getHours();
  let minute = time.getMinutes();
  let Seconds = time.getSeconds();
  if (minute < 10) {
    minute = "0" + minute;
  }
  if (Seconds < 10) {
    Seconds = "0" + Seconds;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (strDate < 10) {
    strDate = "0" + strDate;
  }
  return (
    year +
    "-" +
    month +
    "-" +
    strDate +
    " " +
    hour +
    ":" +
    minute +
    ":" +
    Seconds
  );
}

export function getNowtime(e) {
  let date = e,
    seperator1 = "-", //格式分隔符
    year = date.getFullYear(), //获取完整的年份(4位)
    month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
    strDate = date.getDate(); // 获取当前日(1-31)
  let hour = date.getHours();
  let minute = date.getMinutes();
  let Seconds = date.getSeconds();
  if (month >= 1 && month <= 9) month = "0" + month; // 如果月份是个位数，在前面补0
  if (strDate >= 0 && strDate <= 9) strDate = "0" + strDate; // 如果日是个位数，在前面补0

  let currentdate =
    year +
    seperator1 +
    month +
    seperator1 +
    strDate +
    seperator1 +
    hour +
    seperator1 +
    minute +
    seperator1 +
    Seconds;
  return currentdate;
}
