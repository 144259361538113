import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from "element-ui";
import units from '../common/units';
import store from '../store';
// import rules from '../common/js/rules'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [{
      path: '/',
      name: 'main',
      component: () => import('../views/main/main.vue'),
      meta: "/"
    }, {
      path: "/transactionCenter",
      name: 'order',
      component: () => import('../views/order/order.vue'),
      meta: "/transactionCenter"
    }, {
      path: "/transactiontoday",
      name: 'order',
      component: () => import('../views/order/transactiontoday.vue'),
      meta: "/transactionCenter"
    }, {
      path: "/historicaltransactions",
      name: 'historicaltransactions',
      component: () => import('../views/order/historicaltransactions.vue'),
      meta: "/historicaltransactions"
    },
    // {
    //   path: "/ExclusiveChannelList",
    //   name: 'ExclusiveChannelList',
    //   component: () => import('../views/order/ExclusiveChannelList.vue'),
    //   meta: "/ExclusiveChannelList"
    // },
    {
      path: "/dailystatement",
      name: 'dailystatement',
      component: () => import('../views/order/dailystatement.vue'),
      meta: "/transactionCenter"
    }, {
      path: "/monthlystatement",
      name: 'monthlystatement',
      component: () => import('../views/order/monthlystatement.vue'),
      meta: "/transactionCenter"
    }, {
      path: "/orderFlow",
      name: 'orderFlow',
      component: () => import('../views/order/orderFlow.vue'),
      meta: "/transactionCenter"
    },
    {
      path: "/Instantbill",
      name: 'Instantbill',
      component: () => import('../views/order/Instantbill.vue'),
      meta: "/transactionCenter"
    },
    {
      path: "/cashwithdrawalrecord",
      name: 'cashwithdrawalrecord',
      component: () => import('../views/order/cashwithdrawalrecord.vue'),
      meta: "/transactionCenter"
    },
    {
      path: "/Merchantwithdrawaldetails",
      name: 'Merchantwithdrawaldetails',
      component: () => import('../views/order/Merchantwithdrawaldetails.vue'),
      meta: "/transactionCenter"
    },
    {
      path: "/merchantcenter",
      name: 'merchantcenter',
      component: () => import('../views/merchantcenter/realname.vue'),
      meta: "/merchantcenter"
    },
    {
      path: "/administration",
      name: "administration",
      component: () => import('../views/merchantcenter/administration.vue'),
      meta: "/merchantcenter"
    }, {
      path: "/changepassword",
      name: "changepassword",
      component: () => import('../views/merchantcenter/changepassword.vue'),
      meta: "/merchantcenter"
    }
      // ,{
      //   path:"/PaymentManagement",
      //   name:"PaymentManagement",
      //   component: () => import('../views/merchantcenter/PaymentManagement.vue'),
      //   meta:"/merchantcenter"
      // }
      //   , {
      //   path: "/Rechargeconfiguration",
      //   name: "Rechargeconfiguration",
      //   component: () => import('../views/merchantcenter/Rechargeconfiguration.vue'),
      //   meta: "/merchantcenter"
      // }
      //   , {
      //   path: "/Withdrawalconfiguration",
      //   name: "Withdrawalconfiguration",
      //   component: () => import('../views/merchantcenter/Withdrawalconfiguration.vue'),
      //   meta: "/merchantcenter"
      // }
      , {
      path: "/Administrator",
      name: "Administrator",
      component: () => import('../views/merchantcenter/systemmanagement/Administrator.vue'),
      meta: "/merchantcenter"
    }
      , {
      path: "/Rolemanagement",
      name: "Rolemanagement",
      component: () => import('../views/merchantcenter/systemmanagement/Rolemanagement.vue'),
      meta: "/merchantcenter"
    }, {
      path: "/DataCenter",
      name: "DataCenter",
      component: () => import('../views/DataCenter/Transaction.vue'),
      meta: "/DataCenter"
    }, {
      path: "/Merchantwithdrawal",
      name: "Merchantwithdrawal",
      component: () => import('../views/merchantcenter/Merchantwithdrawal/Merchantwithdrawal'),
      meta: "/DataCenter"
    }, {
      path: "/RefundRecordList",
      name: "RefundRecordList",
      component: () => import('../views/Fundmanagement/RefundRecordList'),
      meta: "/RefundRecordList"
    }, {
      path: "/MerchantwithdrawalPage",
      name: "MerchantwithdrawalPage",
      component: () => import('../views/Merchantwithdrawal/Merchantwithdrawal'),
      meta: "/MerchantwithdrawalPage"
    }, {
      path: "/feedbackinformation",
      name: "feedbackinformation",
      component: () => import('../views/feedbackinformation/feedbackinformation'),
      meta: "/feedbackinformation"
    },
    {
      path: "/openPlatform",
      name: "openPlatform",
      component: () => import('../views/openPlatform/index'),
      meta: "/openPlatform"
    }
    ]

  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }, {
    path: '/register',
    name: 'register',
    component: () => import('../views/register/register.vue')
  }, {
    path: "/retrievepassword",
    name: 'retrievepassword',
    component: () => import('../views/retrievepassword/retrievepassword.vue')
  }
]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
})

//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  if (from.fullPath == '/merchantcenter') {
    if (store.state.info) {
      Message({
        message: units().Pleaseauthenticatewithrealnamefirst,
        type: "error",
      });
      return
    }
    next()
  }
  next()
})

export default router
