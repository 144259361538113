import store from '../store/index'
import en from '../common/en.json'
import tc from '../common/tc.json'
import chs from '../common/chs.json'
export default function changelanguage(){
    let obg = {...en,...tc,...chs}
    let msg = null
	if(store.state.language=="EN"){
        msg = obg.EN
    }else if(store.state.language=="CHS"){
        msg = obg.CHS
    }else if(store.state.language=="TC"){
        msg = obg.TC
    }
    // console.log(msg)
    return msg
}

export function uploadImgToBase64 (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () { // 图片转base64完成后返回reader对象
        resolve(reader)
      }
      reader.onerror = reject
    })
  }