import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: "CHS",
    lang: "zh-hk",
    money: 10000,
    loginstore: false,
    info: false,
    Refresh: false,
    rules: false
  },
  mutations: {
    changelanguage(state, language) {
      state.language = language
      if (language == "CHS") {
        state.lang = "zh-cn"
      } else if (language == "EN") {
        state.lang = "en-us"
      } else if (language == "TC") {
        state.lang = "zh-hk"
      }
    },
    changerules(state, rules) {
      state.rules = rules
    },
    changeRefresh(state, Refresh) {
      state.Refresh = Refresh
    },
    changeloginstore(state, loginstore) {
      state.loginstore = loginstore
    },
    changeinfo(state, info) {
      state.info = info
    }
  },
  actions: {
  },
  modules: {
  }
})
