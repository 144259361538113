import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
Vue.prototype.$axios = axios
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui英文包
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui中文包
import elementtcLocale from 'element-ui/lib/locale/lang/zh-TW';
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
  EN: {
    ...elementEnLocale
  },
  CHS: {
    ...elementZhLocale
  },
  TC: {
    ...elementtcLocale
  }
}

const i18n = new VueI18n({
  locale: 'CHS',
  messages,
})

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

import VueResource from 'vue-resource'
Vue.use(VueResource)

// 到处表格
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import qs from 'qs';
Vue.prototype.$qs = qs;

import units from './common/units.js'
Vue.prototype.$units = units

const eventBus = new Vue()
Vue.prototype.$eventBus = eventBus

import { getNowFormatDate, getNowFormatDate2, getNowFormatDate3, chagetime, getNowtime, getNowFormatDate1 } from './common/getNowFormatDate.js'
Vue.prototype.$getNowFormatDate = { getNowFormatDate, getNowFormatDate2, getNowFormatDate3, chagetime, getNowtime, getNowFormatDate1 }

import en from './common/en.json'
import tc from './common/tc.json'
import chs from './common/chs.json'
let obg = { ...en, ...tc, ...chs }
Vue.prototype.$language = obg

// console.log(language)

// 全局引入图片验证码组件
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

// 引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,  // 不要忘记
  render: h => h(App)
}).$mount('#app')
